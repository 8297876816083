// pages/HomePage.js
import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      <h1>404 Error</h1>
      <p>Not Found!</p>
    </div>
  );
};

export default NotFoundPage;
